/*
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

background: #000;
*/

.logo {
  position: absolute;
  color: hsl(136, 100%, 85%);
  text-shadow:
    0 0 .5em #fff,
    0 0 .5em currentColor; 
}

.base {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rain {
  display: flex;
}
.rain p {
  line-height: 1;
}
.rain span {
  display: block;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  color: #9bff9b11;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}
