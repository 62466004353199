.App_Grid__2IDon {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .App_Grid__2IDon {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .App_Grid__2IDon {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.App_icon__2sTXs {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.App_Child__1S9Tv {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  */
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  /*
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  */
}

@media only screen and (max-device-width: 400px) {
  .App_tab-list-item__DfsLL {
    min-width: none;
  }
}


.App_curtain__Ibmgs {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
  color: rgb(255, 88, 40);
  text-decoration: none;
}

.Navigation_navigation__2EYdF {
  grid-column: 3;
  grid-row: 2;
  position: absolute;
  margin-top: -2em;
  margin-left: 2em;

}

.Navigation_buttonActive__3x7kb {
  padding: 0.4em;
  font-weight: 600;
  color: rgb(58, 58, 58);
  background-color: #ffffff;
  border: none;
  width: 13em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.3em solid rgba(8, 67, 134, 0.199);
  border-bottom: none;
  z-index: 1;
  position: relative;
  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,
    0px -30px 50px 13px rgba(0, 0, 0, 0);
  cursor: pointer;
}
.Navigation_buttonNonActive__35Yt_ {
  padding: 0.5em;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.726);
  background-color: #5781cfc0;
  border: none;
  width: 11.5em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.2em solid rgba(37, 66, 99, 0.705);
  border-bottom: none;
  z-index: 0;
  position: relative;
  cursor: pointer;
}

.Navigation_buttonNonActive__35Yt_:hover {
  background-color: #5781cfd7;
  color: rgb(247, 247, 247);
}

.Navigation_buttonActive__3x7kb:focus {
  outline: none;
}
.Navigation_buttonNonActive__35Yt_:focus {
  outline: none;
}

.tab-list {
  border: 1px solid rgb(253, 85, 36);
  /*
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  */

  background: rgba(48, 16, 7);
  padding-left: 0;

  white-space: nowrap;


  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background: rgba(48, 16, 7);
/*    background-color: black;*/
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}


.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  color: rgb(253, 85, 36);
}


.Profile_info__56i59 {
  padding-top: 0.5em;

  border-radius: 0 0 2em 2em;
}

.Profile_info__56i59 p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__56i59 p {
    font-size: 0.4em;
  }
}


.Profile_info__56i59 button {
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
/*  border: white solid 0.05em; */
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.Profile_info__56i59 button:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_info__56i59 button:focus {
  outline: none;
}


.Profile_network__3kv1C p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_info__56i59 li {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__56i59 li {
    font-size: 0.8em;
  }
}



.Profile_info__56i59 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Profile_special__2ukSj h1 {
  margin-top: 1.2em;
}
.Profile_icon__2a6ww {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Profile_inputDiv__1N_lP {
  padding-top: 1em;
  padding-bottom: 3em;
  height: 3em;
  align-items: center;
}


.Profile_input__2LNpg {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Profile_totals__1dX-A {
  margin-top: 3em;
  height: 3em;
}

.Profile_go__3U6d4 {
  cursor: pointer;
}

.Profile_goMax__2Ij3y {
  padding-left: 10%;
}

.Profile_goMax__2Ij3y h5 {
  font-size: 2em;
}


.Profile_box__2I5ty div {
  width: 280px;
  margin: auto;
}

.Profile_box__2I5ty p {
  color: rgb(253, 85, 36);
  font-size: 0.8em;
}

.Profile_box__2I5ty a {
  color: rgb(253, 85, 36);
  text-decoration: none;
}



.Profile_boxHigh__11eRL div {
  width: 280px;
  margin: auto;
}

.Profile_boxHigh__11eRL p {
  color: white;
  font-size: 0.8em;
}


.Profile_digButton__auK1u {
  background-color: rgba(253,85,36);
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 15em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_digButton__auK1u:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_digButton__auK1u:focus {
  outline: none;
 }


.Profile_digButton__auK1u p {
  display: inline;
  position: relative;
  bottom: 0.5em;
}



 .Profile_blockButton__2dpIh {
   background-color: rgba(253,85,36);
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 12em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_blockButton__2dpIh:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_blockButton__2dpIh:focus {
   outline: none;
  }
  .Profile_blockButton__2dpIh p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



  .Profile_digIcon__2qXzx {
    height: 2em;
    position: relative;
    top: 0.1em;
    right: 1em;
  }


.Profile_checkButton__10Omj {
  background-color: rgba(200,200,200,0.1);
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_checkButton__10Omj:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_checkButton__10Omj:focus {
  outline: none;
 }

 .Profile_checkButton__10Omj:disabled,
 .Profile_checkBbutton__2seBm[disabled]{
   color: rgba(200,200,200,0.9);
 }

.Profile_checkIcon__2M1Q- {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Profile_checkButton__10Omj p {
  display: inline;
  bottom: 0.5em;
}




.Profile_allButton__TyPSN {
  background-color: rgba(253,85,36);
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 2.5em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_allButton__TyPSN:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_allButton__TyPSN:focus {
  outline: none;
 }

.Profile_allButton__TyPSN p {
   display: inline;
   position: relative;
 }



 .Profile_currencyButton__NTGZm {
   background-color: rgba(253,85,36);
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_currencyButton__NTGZm:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_currencyButton__NTGZm:focus {
   outline: none;
  }

 .Profile_currencyButton__NTGZm p {
    display: inline;
    position: relative;
  }



 .Profile_importButton__14RNs {
   background-color: rgb(48, 16, 7);
   color: white;
   font-size: 0.6em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 5em;
   margin-top: 0em;
   margin-left: 1em;
   cursor: pointer;
 }

 .Profile_importButton__14RNs:hover {
   background-color: rgba(253,85,36);
 }


 .Profile_importButton__14RNs:focus {
   outline: none;
  }

 .Profile_importButton__14RNs p {
    display: inline;
    position: relative;
  }


 .Profile_moonButton__2ywFr {
   background-color: rgb(125,0,255); /*rgba(253,85,36);*/
   color: white;
   font-size: 1em;
   border: rgb(125, 0, 255) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_moonButton__2ywFr:hover {
   background-color: rgb(48, 0, 7);
 }


 .Profile_moonButton__2ywFr:focus {
   outline: none;
  }

 .Profile_moonButton__2ywFr p {
    display: inline;
    position: relative;
  }



 .Profile_rampButton__2mt_c {
   background-color: #21bf73;
   color: white;
   font-size: 1em;
   border: #21bf73 solid 0.05em;
   border-radius: 0.5em;
   height: 2.5em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_rampButton__2mt_c:hover {
   background-color: #10af63;
 }


 .Profile_rampButton__2mt_c:focus {
   outline: none;
  }

 .Profile_rampButton__2mt_c p {
    display: inline;
    position: relative;
  }



 .Profile_scanButton__13H3F {
   background-color: rgba(253,85,36);
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .Profile_scanButton__13H3F:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_scanButton__13H3F:focus {
   outline: none;
  }

 .Profile_scanButton__13H3F p {
    display: inline;
    position: relative;
  }


.Profile_larger__142-2 {
  font-size: 1.2em;
}

.Profile_stone__1yWoD {
  position: relative;
  top: 2em;
}

.Profile_animatestone__eQF8C
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: Profile_textclip__25K2x 2s linear infinite;
          animation: Profile_textclip__25K2x 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@-webkit-keyframes Profile_textclip__25K2x {
  to {
    background-position: -200% center;
  }
}

@keyframes Profile_textclip__25K2x {
  to {
    background-position: -200% center;
  }
}


.Profile_header__3dCy8 {
  padding-top: 10em;
  height: 5em;

  background-image: url(/static/media/aresheader.62a4f4a1.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 50%;

}




.Profile_logo__742kq {
  padding-top: 0.5em;
  background-image: url(/static/media/ares1500x.b4cd15bc.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  border-radius: 0 0 2em 2em;
}

.Profile_logo__742kq p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_logo__742kq p {
    font-size: 0.4em;
  }
}

.Profile_logo__742kq .Profile_xx__FeHc6 {
  display: flex;
  justify-content: center;
  padding-top: 25%;
  padding-bottom: 25%;
}



.Profile_infologo__15gD3 {
/*
  background-image: url("../assets/ares1500x30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  */

}

/*
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

background: #000;
*/

.Origin_logo__hbT3L {
  position: absolute;
  color: hsl(136, 100%, 85%);
  text-shadow:
    0 0 .5em #fff,
    0 0 .5em currentColor; 
}

.Origin_base__2zrXm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Origin_rain__2gPdr {
  display: flex;
}
.Origin_rain__2gPdr p {
  line-height: 1;
}
.Origin_rain__2gPdr span {
  display: block;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  color: #9bff9b11;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black; /*linear-gradient(#472712, #030201);*/
  color: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

